/* Not react CSS */

:root {
  --bg-color: #413752;
  --light-pink: #9783af;
  --light-pink-shadow: #93668d;

  --item-border: #7c458b;
  --item-border-top: #8a6dca;

  --dist-transparent: #9783af89;

  --blurple: #6f5d97;
  --light-blurple: #8984ad;
  --txt-color: #9783af;
  --dark-black: #312333;
  --black: #253243;
  --green: #5b9979;
  --yellow: #b9b772;
  --key-border: #253243;
  --key-black: #253243;
  --key-green: #5a756b;
  --key-yellow: #817c63;
  --key-highlight: #7c458b99;
  --key-highlight-bg: #7c458b1f;
  --key-txt-color: #917d89;
  --modal-bg: #433a51;
  --semi-transparent: #000000af;

  --appbar-height: 5.3rem;
  --letter-height: 3.5rem;
  --key-width: 5rem;
}

@font-face {
  font-family: "botdle-blocky";
  src: url("../public/font.ttf");
}
@font-face {
  font-family: "botdle-skinny";
  src: url("../public/botdle-skinny.ttf")
}

* {
  margin: 0px;
}

body {
  background-color: var(--bg-color);
  font-family: "botdle-skinny";
  color: var(--txt-color);
  text-align: center;
  font-size: 20px;
}
html {
  font-size: 16px;
}

/* Main container */

.playing {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

/* */

/* appbar */

.page-header {
  max-width: 100vw;
  width: 100vw;
  display: flex;
  height: 3.5rem;
}
.appbar {
  background-color: var(--light-pink);
  width: 100%;
  height: calc(100% - 1rem);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* background-color: var(--light-pink);

  height: 5.3rem;
  width: 100vw;
  max-height: 2.6rem;

  display: flex;
  align-items: center;
  justify-content: space-around; */
}
.left-bar, .center-bar, .right-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 1.3rem;
  height: 1.3rem;
  margin: 0.5rem;
  cursor: pointer;
}
.games, .settings {
  outline: none;
}
.heading-image {
  height: 1.7rem;
  display: flex;
}
/* ^^^ scale imgs later */

/* How-to-play modal */

.modal {
  background-color: var(--modal-bg);

  display: grid;
  grid-template-rows: auto 1fr auto;

  height: 100%;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--semi-transparent);

  z-index: 5000;
}
.header-close {
  background-color: transparent;
  border: none;
  /* the close button is a <button> elem, so those need to be disabled */

  display: flex;
  align-self: center;
  justify-content: flex-end;
}
.close-button {
  width: 1rem;
  height: 1rem;
}
h2.header-title {
  font-size: 3rem;
}
.modal-body {
  margin: 0rem 10rem;
  padding-right: 1rem;
  /* ^ to push the scrollbar to the right */

  max-height: 95%;
  max-width: 960px;

  overflow: auto;

  text-align: left;
  font-size: 1.7rem;
  line-height: 2.5rem;

  justify-self: center;
}
.container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 10000;
}

.sub-list {
  list-style: lower-alpha;
  /* for list inside of a list */
}
.color-emoji {
  font-size: 1.4rem;
  /* 🟩⬛🟨 to be bigger */
}
.modal-body .item {
  height: fit-content;
  width: fit-content;
}
.li-guess, .modal-body li {
  line-height: 1.2 !important;
  margin: 2rem;
}
li .row {
  margin: 1rem 0rem;
}
.modal-body .row {
  grid-template-columns: 0px 0px 1fr 0px 0px;
}
.modal-body .txt {
  font-size: 1.2rem;
  /* padding: 0.  5rem; */
}
.htp-note {
  font-size: 1rem;
}
.htp-ex {
  color: var(--green);
}


/* */
.play-area {
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
}

.ans {
  /* place where you type in letter */
  display: flex;
  align-items: center; 
  justify-content: center;
  max-height: calc(100% - 0.2rem);
}
.underline-letter {
  width: calc((100% / 5) - 0.4rem);
  max-width: 5rem;
  height: fit-content;
  margin: 0px 0.2rem;
  border-bottom: 0.2rem solid var(--txt-color);

  font-size: 1.7rem;
  text-align: center;
}

/* Row (guessing area) */

.entries {
  min-width: 50%;
  max-width: 100vw;
  height: 100%;
  width: fit-content;
  
  overflow: hidden;
  
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column-reverse;
  /* max-height: 80%;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  scroll-behavior: smooth;

  margin-top: 0.5rem; */
}
.end .entries, .keyboard-closed .play-area-cont {
  max-height: 100%;
  bottom: 0;
}
.row {
  height: calc(20% - 15px);
  max-width: 100vw;
  max-height: 5rem;
  /* ^^^ update in media query */
  display: grid;
  grid-template-columns: 1fr 4rem auto 4rem 1fr;
  width: fit-content;
  min-width: 90%;
  margin-top: 15px;
  justify-content: center;
}
/* .row-ans {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 1rem;
} */
.row-ans, .score {
  max-height: 3rem;
  min-height: 2rem;
  min-width: 2rem;
  max-width: 3rem;

  height: calc(var(--letter-height) - 0.5rem);
  width: calc(var(--letter-height) - 0.5rem);

  align-self: center;
}
.score {
  color: var(--yellow);
}
.row-ans-cont {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}
.score-cont {
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
}

.guess {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-self: center;
}

.guide-wrapper li {
  margin-left: 0;
  list-style-position: inside;
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-position */
  line-height: 2.5;
  /* basically line spacing; doesn't increase font size*/
}

.item {
  height: var(--letter-height);
  width: var(--letter-height);
  min-width: 2rem;
  min-height: 2rem;

  border: 5px solid var(--item-border);
  border-top-color: var(--item-border-top) !important;
  margin: 0px 2px;
  
  align-self: center;
  
  color: var(--txt-color);
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  /*
  border: 5px solid var(--item-border);
  border-top-color: var(--item-border-top) !important;
  margin: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 2rem;
  min-height: 2rem;

  text-transform: uppercase; */
}
.txt {
  font-size: calc(var(--letter-height) / 2.3);

  user-select: none;
  font-family: "botdle-blocky";
}
.black {
  background-color: var(--black);
}
.green {
  background-color: var(--green);
  color: var(--black);
}
.yellow {
  background-color: var(--yellow);
  color: var(--bg-color);
}

/* */
button {
  cursor: pointer;
  font-family: "botdle-skinny";
  text-transform: uppercase;
}

/* */

.error-msg {
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1000;

  background-color: #6c617a;
  color: #271c24;

  padding: 10px 20px;
  border-radius: 10px;

  font-size: 1.5em;

  animation: 1s ease-in-out 0s 1 normal none running fade;
}
@keyframes fade {
  0% {
    background-color: transparent;
    color: transparent;
  }
  50% {
    background-color: var(--blurple);
    color: var(--dark-black);
  }
  70% {
    background-color: var(--blurple);
    color: var(--dark-black);
  }
  100% {
    background-color: transparent;
    color: transparent;
  }
}
/* */
a {
  color: var(--light-blurple)
}
a:hover {
  color: var(--blurple)
}
a:active {
  color: var(--black)
}

/* Start screen */
.start-button {
  width: 15rem;
  height: 4.5rem;

  background-color: var(--light-pink);
  border: 3px solid var(--dark-black);

  color: var(--dark-black);
  font-size: 1.5rem !important;
  outline: none;

  margin-top: 3rem;
}
.start-button:hover {
  background-color: var(--light-pink-shadow);
}
.start-button:active {
  border-bottom: 2px solid var(--dark-black);
  border-top: 6px solid var(--dark-black);
  background-color: var(--item-border);

  color: #000;
}
.start-cont {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
/* */

/* End modal */
.end-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: fit-content;
  width: fit-content;
  min-height: fit-content;
  min-width: fit-content;
  max-width: 100vw;
  max-height: 100vw;

  padding: 10px 40px;

  display: grid;
  grid-template-rows: auto 1fr;

  z-index: 10000;
  text-align: center;
}
.end-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: var(--semi-transparent);

  z-index: 5000;
}
.end-close {
  width: 100%;

  background-color: transparent;

  border: none;

  font-size: 20px;

  display: flex;
  justify-content: flex-end;
}

.end-contents {
  display: grid;
  max-width: 100%;
}
.end-container {
  width: 50%;
  height: 50%;
  min-width: fit-content;
  min-height: fit-content;

  position: fixed;
  top: 0;
  left: 0;
  transform: translate(50%, 50%);
  z-index: 10000;
}
.end, .modal {
  border-radius: 20px;
}
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 10px;
  margin: 10px;

  text-align: center;

  color: var(--green);
}
.streaks-cont {
  display: flex;
  justify-content: center;
  color: var(--green)
}
.streak {
  margin: 0.3rem;
}
.streak-label {
  font-size: 0.9rem;
}
.tries {
  margin-bottom: 1rem;
}
/* distributions */
.dist-row {
  display: grid;
  grid-template-columns: 2rem 1fr;
  margin: 1rem;
}
.dist-percent-cont {
  width: 20rem;
}
.dist {
  color: var(--bg-color);
  background-color: var(--dist-transparent);

  height: 100%;

  padding-right: 5px;
  border-radius: 5px;

  text-align: right;
  font-weight: bold;
}
.highlight .dist {
  background: none;
  background-color: var(--green);
  background-image: linear-gradient(to right, var(--key-green) 1%, transparent 80%, var(--yellow));
  /* ^ replace w/ pixel gradient */
}
.highlight .dist-num {
  color: var(--green);
}

.dist-num {
  display: flex;
  align-items: flex-start;
  color: var(--dist-transparent);
}
/* */

.footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-button, .footer-close, .start-button {
  color: var(--dark-black);
  background-color: var(--light-pink);

  border: 2px solid var(--dark-black);
  border-bottom: 4px solid var(--dark-black);
  border-top-color: var(--blurple);

  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  
  font-size: 1.2rem;
}
.footer-close {
  font-size: 1.5rem;
}
.share-button:hover {
  background-color: var(--dist-transparent);
}
.share-button:active {
  border: 2px solid var(--dark-black);
  border-top: 4px solid var(--dark-black);

  background-color: var(--item-border);
  color: #000;
}
/* */

/* score */
/* .score {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: var(--yellow);
} */
.score > img {
  width: 1rem;
  height: 1rem;
}
/* */

/* .playing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  position: fixed;
  height: 100%;
  width: 100%;
} */

/* */

/* keyboard */
.keyboard-cont-hidden ~ .above-keyboard-cont {
  align-self: flex-end;
}
.above-keyboard-cont {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.toggle-keyboard-cont {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}
.toggle-keyboard {
  text-align: right;
  font-size: 1.7rem;
  
  height: fit-content;
  width: fit-content;
  user-select: none;
  cursor: pointer;

  margin-right: 2rem;
}
.keyboard-animate-off, .keyboard-animate-on {
  animation: 300ms ease-in 0s 1 normal none running toggle-keyboard;
}
.keyboard-animate-off .toggle-keyboard, .keyboard-animate-on .toggle-keyboard {
  animation: 300ms ease-in 0s 1 normal none running toggle-keyboard-arrow;
}
.keyboard-animate-on {
  animation-name: toggle-keyboard-reverse;
}
.keyboard-animate-on .toggle-keyboard {
  animation-name: toggle-keyboard-arrow-reverse;
}

.keyboard-cont-hidden {
  display: none;
}
.playing .footer-keyboard {
  background-color: var(--bg-color);
  width: 100%;
  max-width: 100vw;
  display: grid;
  grid-template-rows: 4rem 1fr;
  height: 100%;
  /* width: 100vw;
  
  display: flex;
  align-items: center;
  flex-direction: column;
  
  position: fixed;
  bottom: 0;
  
  background-color: var(--bg-color); */
}
.keyboard-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-keyboard-hidden {
  grid-template-columns: 4rem 0;
  height: fit-content;
}

.keyboard {
  max-width: 100vw;
  width: fit-content;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
  /* width: 29.1rem;
  height: 7.9rem;
  max-height: 30vh;
  max-width: 98%;
  
  margin-bottom: 1rem;
  margin: 0.2rem 1rem;
  
  display: grid;
  grid-template-rows: 1fr 1fr 1fr; */
}
.keyboard-row {
  height: 98%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.2rem;
  user-select: none;
}
.key {  
  /* width: 2rem;
  height: 85%; */
  width: calc(var(--key-width) - 14px);
  height: 90%;
  max-height: calc(100% - 4px);

  border: 2px solid var(--key-border);
  margin: 0px 5px;
  
  font-size: 1.7rem;
  
  color: var(--key-txt-color);
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
}
.key-cont {
  height: 95%;
}
.key-green {
  background-color: var(--key-green);
  color: var(--bg-color);
}
.key-yellow {
  background-color: var(--key-yellow);
  color: var(--bg-color);
}
.key-black {
  background-color: var(--key-black);
}
.key-highlight {
  border-color: var(--key-highlight);
  background-color: var(--key-highlight-bg);
}
.enter, .delete {
  width: 4rem;
}
/* */
/*
@media (max-width: 500px) {
  html {
      font-size: 15px;
  }
  .keyboard {
      height: 14rem;
  }
  .appbar {
      height: 3.5rem;
      max-height: 4.7rem;
  }
  :root {
    --appbar-height: 3.5rem;
    --keyboard-height: 14rem;
  }
  .play-area {
      height: 40rem;
      width: 20rem;
  }
  .item {
    margin: 2px 2px;
    border-width: 2px;
  }
  .key {
    margin: 0px 1px;
    font-size: 1rem;
    border-width: 1px;
    width: 1.5rem;
    height: 100%;
  }
  .enter, .delete {
    width: 3rem;
  }
  .heading-image {
    height: 1.5rem;
  }
  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@media (max-width: 1000px) and (min-width: 501px) {
  html {
      font-size: 20px;
  }
  .keyboard {
      height: 10rem;
      margin-top: 0.3rem;
  }
  .appbar {
      height: 3.3rem;
      max-height: 3.3rem;
  }
  :root {
    --appbar-height: 3.3rem;
    --keyboard-height: 10rem;
  }
}
@media (max-width: 1000px) and (min-width: 501px) and (orientation: portrait) {
  html {
      font-size: 20px;
  }
  .play-area {
      height: 38rem;
      width: 30rem;
  }
  .keyboard {
      height: 25rem;
      margin-top: 0.3rem;
      width: 98vw;
  }
  .keyboard-row {
      font-size: 1.6rem;
  }
  .appbar {
      height: 5rem;
      max-height: 8.3rem;
  }
  :root {
    --appbar-height: 5rem;
    --keyboard-height: 25rem;
  }
}
@media (max-width: 1500px) and (min-width: 1001px) {
  html {
      font-size: 25px;
  }
  .keyboard {
      height: 7.5rem;
  }
  .invisible-scroll {
    height: 3rem;
  }
  :root {
    --keyboard-height: 7.5rem;
    --appbar-height: 2.5rem;
  }
  .appbar {
    max-height: 2.5rem;
  }
}
@media (max-width: 2500px) and (min-width: 1501px) {
  html {
      font-size: 35px;
  }
  :root {
    --appbar-height: 5rem;
  }
  .appbar {
      height: 5rem;
  }
}
@media (min-width: 2501px) {
  html {
      font-size: 40px;
  }
  .play-area {
      height: 25rem;
  }
}
@media (min-width: 4000px) {
  html {
      font-size: 50px;
  }
  :root {
    --appbar-height: 6rem;
  }
  .appbar {
      height: 6rem;
  }
} 
*/
/* viewport resolution */

.vp-cont {
  position: fixed;
  z-index: 5000;
  top: 20px;
  right: 20px;
  /* display: none; */
}
.vp-button {
  background-color: #a17296;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1rem;
  color: #32243b;
  font-weight: bold;
  border: 5px solid #32243b;
}
.vp-button:active {
  background-color: #9b6590;
}
.vp {
  font-size: 1.5rem;
}

/* answer and score */

.unanswered .row-ans, 
.unanswered .score, 
.answered .score, 
.coloring .score,
.colored .score {
color: var(--bg-color);
}
.answered .row-ans {
  animation: 0.2s ease-in 0s 1 normal none running ans-fade-in;
}
/* */

.unanswered .guess, .unanswered .item {
animation: 0.3s ease-in-out 0.01s 1 normal none running flip-anim;
}

/* */

@keyframes flip-anim {
0% {
  transform: rotateX(0deg);
}
100% {
  transform: rotateX(180deg);
}
}

@keyframes transition-black {
  0% {
      background-color: transparent;
      border-color: var(--item-border);
      transform: rotateX(0deg);
  }
  100% {
      background-color: var(--black);
      border-color: var(--black);
      transform: rotateX(180deg)
  }
}
@keyframes transition-yellow {
0% {
    background-color: transparent;
    border-color: var(--item-border);
    transform: rotateX(0deg);
}
100% {
    background-color: var(--yellow);
    border-color: var(--yellow);
    transform: rotateX(180deg)
}
}
@keyframes transition-green {
0% {
    background-color: transparent;
    border-color: var(--item-border);
    transform: rotateX(0deg);
}
100% {
    background-color: var(--green);
    border-color: var(--green);
    transform: rotateX(180deg)
}
}

@keyframes ans-fade-in {
0% {
  font-size: 1px;
  color: var(--bg-color);
}
100% {
  color: var(--txt-color);
}
}
@keyframes score-fade-in {
0% {
  font-size: 1px;
  color: var(--bg-color);
}
100% {
  color: var(--yellow);
}
}

@keyframes toggle-keyboard-arrow {
  0% {}
  100% {
    transform: rotateZ(180deg);
  }
}
@keyframes toggle-keyboard {
  0% {
    height: var(--keyboard-height);
  }
  100% {
    height: 0px;
  }
}
@keyframes toggle-keyboard-arrow-reverse {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}
@keyframes toggle-keyboard {
  0% {
    height: 0px;
  }
  100% {
    height: var(--keyboard-height);
  }
}

/* media queries */

@media(max-width: 500px) {
  :root {
      --letter-height: 2.5rem;
      --key-width: 2.5rem;
  }
  .row {
      height: calc(20% - 5px);
      margin-top: 5px;
  }
  .key {
    margin: 0px 2px;
    border-width: 2px;
  }
  .underline-letter {
    font-size: 1.5rem;
    min-width: calc(100% / 5);
  }
  .modal-body {
    margin: 0rem 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  h2.header-title {
    font-size: 2rem;
  }
  .header {
    grid-template-columns: 2rem 1fr 2rem;
  }
  .footer-close {
    font-size: 1rem;
    margin: 0;
  }
}
@media(min-width: 1500px) {
  :root {
      --letter-height: 4.5rem;  
      --key-width: 7rem;
  }
  .row {
      max-height: 7rem;
  }
  .page-header {
      height: 5.5rem;
  }
  .footer-keyboard {
      grid-template-rows: 5rem 1fr;
  }
}
@media(min-width: 2500px) {
  :root {
      --letter-height: 5.5rem;
  }
  .row {
      max-height: 9rem;
  }
  .page-header {
      height: 7rem;
  }
  .footer-keyboard {
      grid-template-rows: 7rem 1fr;
  }
  .underline-letter {
    font-size: 3.5rem;
  } 
  .key {
    font-size: 2.5rem;
  }
  .toggle-keyboard {
    font-size: 4rem;
    margin-right: 4rem;
  }
  .heading-image {
    height: 3.5rem;
  }
  .modal-body {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  h2.header-title {
    font-size: 6rem;
  }
  .icon, .close-button {
    width: 3rem;
    height: 3rem;
  }
  .end-modal .close-button {
    width: 1rem;
    height: 1rem;
  }
  .modal-body .txt {
    font-size: 3rem;
    padding: 1rem;
  }
  .htp-note {
    font-size: 1rem;
  }
  .color-emoji {
    font-size: 2.5rem;
  }
  .footer-close {
    font-size: 3.5rem;
    padding: 1rem 1.5rem;
  }
}